import React, { useEffect, useState } from 'react';
import BlogList from './Utils/BlogList';
import { fetchBlogs } from '../services/api';
import "./scss/Blogs.scss"
import BlogStrip from './Utils/BlogStrip';

const Blogs = () => {
    const [blogsData, setBlogsData] = useState([]);
    const [loading, setLoading] = useState(true);
    

    const getBlogs = async () => {
        try {
            const response = await fetchBlogs();
           
            // Transform the response data to the desired format
            if (response?.length > 0) {
               
                const transformedBlogs = response?.map(blog => {
                    return {
                        id: blog.documentId,
                        image: blog.blog_image.url,
                        title: blog.blog_title,
                        description: blog.description,
                        readTime: blog.duration,
                        date: blog.postDate
                    };
                });
                
                setBlogsData(transformedBlogs);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false); // Set loading to false after fetching
        }
    };

    useEffect(() => {
        getBlogs();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Show a loading message or spinner
    }

    return (
        <React.Fragment>
            <BlogStrip title="Expert's Insights on Emerging Tech & Biz Transformation" />
            <div className="blogs--list--wrapper">
                <BlogList blogs={blogsData} />
            </div>
        </React.Fragment>
    );
};

export default Blogs;
