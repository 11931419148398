import React from 'react';
import './BlogCard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const BlogCard = ({ image, title, description, readTime, date, click }) => {
    return (
        <div className="blog-card">
            <div onClick={click} className="blog-card__image" style={{ backgroundImage: `url(${image})` }}></div>
            <div className="blog-card__content">
                <p onClick={click} className="blog-card__title">{title}</p>
                {description && <p className="blog-card__description">{description}</p>}
                <hr />
                <div className="blog-card__footer">
                    <span className="blog-card__read-time"><FontAwesomeIcon icon={faClock} /> {readTime} min read</span>
                    <span className="blog-card__date"> <FontAwesomeIcon icon={faCalendarAlt} /> {date}</span>
                </div>
            </div>
        </div>
    );
};

export default BlogCard;
