import React, { useState } from "react"
import "./../scss/Input.scss"

const TextInput = ({
    change,
    name='input',
    isError,
    errorMsg = "This field is required",
    label,
    required = false
}) => {
    const [inputValue, setInputValue] = useState(null)

    return (
        <React.Fragment>
            <div className="input--wrapper">
                {label && <div>
                    <span className="input--label">{label}</span>
                    {required && <span className="input--asterisk">*</span>}
                </div>}
                <input name={name} value={inputValue} onChange={((e) => {
                    setInputValue(e.target.value)
                    change(e)
                })} />
                {isError && <span className="input--error">{errorMsg}</span>}
            </div>
        </React.Fragment>
    )
}

export default TextInput