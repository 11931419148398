import React, { useState } from "react"
import TextInput from "./Utils/TextInput"
import TextareaComp from "./Utils/Textarea"
import ButtomComp from "./Utils/ButtonComp"
import "./scss/GetInTouch.scss"

const GetInTouch = () => {
    const [clientInfo, setClientInfo] = useState({
        name: '',
        email:'',
        message: '',
    })

    const onValueChange = (e) => {
        setClientInfo((prev) => ({
            ...prev,
            [e.target.name] : e.target.value
        }))
    }

    return (
        <React.Fragment>
        <div className="get--in--touch--wrapper">
            <div className="get--in--touch--title">
                Boost Your Business Growth by 3X with Custom Software Solutions
            </div>
            <div className="get--in--touch--details">
                <TextInput required={true} isError={false} label="Name" name='name' change={onValueChange} />
                <TextInput required={true} isError={false} label="Email" name='email' change={onValueChange} />
                <TextareaComp required={true} change={onValueChange} label="Message" name='message' />
            </div>
            <div className="get--in--touch--btn">
                <ButtomComp buttonTitle="Consult With Expert" />
            </div>
        </div>
        </React.Fragment>
    )
}

export default GetInTouch