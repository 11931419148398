import React from "react";
import BlogList from "./BlogList";
import "../scss/BlogArticle.scss"

const BlogArticle = ({ title, blogs }) => {

  return (
    <React.Fragment>
      <div className="blog--related--article">
        <div className="blog--related--text">{title}</div>
        <BlogList blogs={blogs} />
      </div>
    </React.Fragment>
  );
};

export default BlogArticle;
