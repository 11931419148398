import React from "react"
import "./popup.scss";

const Modal = ({
    isOpen,
    children
}) => {

    return (
        <React.Fragment>
            <div className={`menu--popup ${isOpen ? "menu--open" : ""}`}>
                {children}
            </div>
        </React.Fragment>
    )
}

export default Modal