import React from "react"
import "../components/scss/AboutUs.scss"
import CompanyValues from "./Utils/CompanyValues"
import WeAreSpontaneous from "./Utils/AboutUsHome"
import OurStory from "./Utils/OurStory"

const AboutUs = () => {
    

    return (
           <div className="About--us--style">
                <WeAreSpontaneous />
                <OurStory />
                <CompanyValues />
           </div>
    )
}

export default AboutUs