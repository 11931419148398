import React from "react";

interface ImageCompProps {
  width?: string;
  height?: string;
  source: string;
}

const ImageComp: React.FC<ImageCompProps> = ({ width = "100%", height = "auto", source, ...rest }) => {
  return (
    <img
      src={source}
      {...rest}
      style={{ width, height, maxWidth: "100%", objectFit: "contain" }}
      alt="Company value representation"
    />
  );
};

export default ImageComp;
