import React from 'react';
import StatCard from './StateCard';

const StatsSection = () => {
  const stats = [
    { value: '11+', description: 'Years of Experience' },
    { value: '99%', description: 'On-Time Project Delivery' },
    { value: '85%', description: 'Repeat Business' },
    { value: '90%', description: 'Client Retention Rate' },
  ];

  return (
    <div className="stats-section">
      {stats.map((stat, index) => (
        <StatCard key={index} value={stat.value} description={stat.description} />
      ))}
    </div>
  );
};

export default StatsSection;
