import React from "react";
import "./scss/ServicePage.scss";
import ButtomComp from "./Utils/ButtonComp";
import FAQSection from "./Utils/FAQSection";
import BlogArticle from "./Utils/BlogArticle";
import TechSection from "./Utils/TechSection";
import OurSolutions from "./Utils/OurSolutions";
import TestimonialCarousel from "./Utils/TestimonialCarousel";
import ImageWithTextSectionGrid from "./Utils/ImageWithTextSectionGrid.js";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getBlogsByCatName, getPageData } from "../services/api";
import { IMAGE_URL } from "../services/api";

const ServicePage = () => {
  const [pageData, setPageData] = useState([]);
  let { service } = useParams();
  const [relatedBlogs, setRelatedBlogs] = useState([]);

  const getRelatedBlogs = async (categories) => {
    console.log(categories, "categoriescategoriescategories");
    try {
      const relatedBlogs = await getBlogsByCatName(categories);
      console.log(relatedBlogs, "relatedBlogs");
      if (relatedBlogs?.length > 0) {
        const transformedBlogs = relatedBlogs?.map((blog) => {
          return {
            id: blog.documentId,
            image: blog.blog_image.url,
            title: blog.blog_title,
            description: blog.description,
            readTime: blog.duration,
            date: blog.postDate,
          };
        });
        console.log(transformedBlogs, "transformedBlogs");
        setRelatedBlogs(transformedBlogs);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getPageInfo = async () => {
    try {
      const response = await getPageData(service);
      console.log(response, "ndnsndsndnsndsnd");
      if (response?.categories?.length > 0) {
        getRelatedBlogs(
          response?.categories?.map((item) => item.category_name)
        );
      }
      console.log(response, "responsenndsd");
      setPageData(response);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getPageInfo();
  }, [service]);

  const dotnetFaqs = [
    {
      question: "What .NET development services do you offer?",
      answer:
        "We offer a comprehensive range of .NET development services, including web application development, enterprise solutions, cloud-based applications, API development, and legacy application migration to .NET.",
    },
    {
      question: "Which .NET technologies do you specialize in?",
      answer:
        "Our expertise covers a wide array of .NET technologies, including ASP.NET Core, Blazor, WPF, Xamarin, Entity Framework, and .NET MAUI. We also work with related technologies like Azure and SQL Server.",
    },
    {
      question: "Can you modernize existing .NET applications?",
      answer:
        "Yes, we specialize in modernizing legacy .NET applications. We can upgrade your existing applications to the latest .NET versions, optimize performance, and migrate them to cloud environments if needed.",
    },
    {
      question: "How do you ensure the scalability of .NET applications?",
      answer:
        "We design .NET applications with scalability in mind, utilizing microservices architecture, cloud-based solutions like Azure, and best practices in code optimization to ensure your application can grow with your business needs.",
    },
    {
      question: "What is your approach to .NET application security?",
      answer:
        "Security is a top priority in our .NET development process. We implement secure coding practices, conduct regular security audits, and comply with industry standards like OWASP, GDPR, and HIPAA to protect your application.",
    },
    {
      question: "Do you offer maintenance and support for .NET applications?",
      answer:
        "Yes, we provide ongoing maintenance and support services for .NET applications, including bug fixes, performance enhancements, and feature updates to keep your application running smoothly and securely.",
    },
    {
      question:
        "Can you integrate third-party services with .NET applications?",
      answer:
        "Absolutely. We have extensive experience integrating third-party services and APIs with .NET applications, ensuring seamless interoperability and enhanced functionality for your software solutions.",
    },
  ];

  return (
    <React.Fragment>
      {pageData ? (
        <div className="service--wrapper">
          <div className="main---services">
            <div className="service--details">
              <div className="service--details--title">
                {pageData?.header?.title}
              </div>
              <div className="service--details--description">
                <span className="desc--1">
                  {pageData?.header?.description1}
                </span>
                <span className="desc--2">
                  {pageData?.header?.description2}
                </span>
              </div>
              <ButtomComp buttonTitle={pageData?.header?.buttonText} />
            </div>
          </div>
          <TechSection
            technologies={pageData?.technologies?.techStack}
            title={pageData?.technologies?.techTitle}
            description={pageData?.technologies?.techDesc}
          />
          <OurSolutions
            title={pageData?.solutions?.title}
            description={pageData?.solutions?.desc}
            services={
              pageData?.solutions?.solution?.length > 0
                ? pageData?.solutions?.solution?.map((item) => ({
                    title: item?.solutionName,
                    description: item?.solutionDesc,
                    backImage: IMAGE_URL + item?.image?.url,
                  }))
                : []
            }
          />
          <div className="image--with--section--wrapper-bg">
            {pageData?.dynamic_components?.map((item, index) => (
              <ImageWithTextSectionGrid
                reverse={index % 2 ? true : false}
                source={IMAGE_URL + item.section_image.url}
                description={item.section_description}
                title={item.section_title}
              />
            ))}
          </div>
          {/* <TestimonialCarousel /> */}
          {relatedBlogs?.length > 0 && (
            <div className="div--padded">
              <BlogArticle
                blogs={relatedBlogs}
                title=".NET Development Insights"
              />
            </div>
          )}
          <FAQSection faqTitle=".NET Development FAQs" faqs={dotnetFaqs} />
        </div>
      ) : (
        <h1>noData</h1>
      )}
    </React.Fragment>
  );
};

export default ServicePage;
