import React from "react";
import "./Footer.scss"; // Import the SCSS file for styling
import ImageComp from "./ImageComp";
import TextComp from "./TextComp";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>Locations</h3>
          <p>
            <strong>Corporate Office, India</strong>
            <br />
            B 611, Shalin Square, S.P. Ring Road, Hathijan Circle, Vinzol,
            <br />
            Ahmedabad - 382445,
            <br />
            Gujarat, India
          </p>
          <p>
            <strong>Register Address, India</strong>
            <br />
            26, Shivanand Bunglows, Nr. Annapurna hotel, behind express highway
            Jashodanagar, Ahmedabad, Gujarat – 382445
            <br />
            India
          </p>
        </div>
        <div className="footer-section">
          <h3>Services</h3>
          <ul>
            {/* <li
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/services/web-application`)}
            >
              Software Development
            </li> */}
            <li
              onClick={() => navigate(`/services/custom-application`)}
              style={{ cursor: "pointer" }}
            >
              Application Development
            </li>
            <li
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/services/maintenance-and-support`)}
            >
              Maintenance and Support
            </li>
            <li
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/services/mobile-application`)}
            >
              Mobile App Development
            </li>
            <li
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/services/web-application`)}
            >
              Web Development
            </li>
          </ul>
        </div>
        {/* <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li>Services</li>
            <li>Solutions</li>
            <li>Technologies</li>
            <li>Industries</li>
            <li>Areas We Serve</li>
            <li>Success Stories</li>
            <li>Resources</li>
            <li>Careers</li>
            <li>Insights</li>
          </ul>
        </div> */}
        <div className="footer-section">
          <h3>Contact</h3>
          <p>
            hello@spontaneousenterprise.com
            <br />
            nipa@spontaneousenterprise.com
            <br />
            Sales +91 7016570115
            <br />
            Careers +91 7041242169
          </p>
          <div className="tag--line">
            <ImageComp
              height="100px"
              width="300px"
              source={"/spontaneous-Logo.png"}
            />
            <TextComp size="large">
              Your challenges, Our innovative solutions
            </TextComp>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© [2017] · All rights reserved to Spontaneous Enterprises.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
