import React from 'react';
import TechnologyCard from './TechnologyCard';

const TechnologiesSection = ({
  title,
  description,
  technologies = []
}) => {

  console.log(technologies, 'technologies')
  return (
    <div className="techology-section">
      <h2>{title}</h2>
      <p>
        {description}
      </p>
      <div className="tech-grid">
        {technologies.map((tech, index) => (
          <TechnologyCard key={index} logo={tech.logo} name={tech.name} />
        ))}
      </div>
    </div>
  );
};

export default TechnologiesSection;
