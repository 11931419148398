import React from "react";
import "./popup.scss";
import CustomSolution from "./CustomSolution";

const PopupMenu = ({ menu = [], IsShowCustomSolution = false, columns = 1, click }) => {
  return (
    <React.Fragment>
        <div className="menu--services">
          {menu.map((section, index) => (
            <div className="menu--section" key={index}>
              <div className="menu--title">{section.title}</div>
              <div className="menu--options">
                <ul className={`${columns == 1 ? 'menu--column--one' : 'menu--column--two'}`}>
                  {section.options.map((option, idx) => (
                    <li onClick={() => click(option)} className="menu--list--item" key={idx}>{option?.name}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
          {IsShowCustomSolution && <CustomSolution />}
        </div>
    </React.Fragment>
  );
};

export default PopupMenu;
