import React from "react";
import "./Button.scss"

const ButtomComp = ({ buttonTitle, height = 'auto', width = 'auto', type="button", disabled = false, click = () => null }) => {
  return (
    <React.Fragment>
      <div className="button--container">
      <span className="button--mas">{buttonTitle}</span>
        <button type={type} onClick={click} disabled={disabled} className="button">{buttonTitle}
        </button>
      </div>
    </React.Fragment>
  );
};

export default ButtomComp;
