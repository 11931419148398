import React, { useEffect } from "react";
import "./CompanySection.scss";
import ButtomComp from "./ButtonComp";
import ContactFormModal from "./ContactFormModal";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

const CompanySection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVideo, setIsVideo] = useState();
  const navigate = useNavigate();

  // Some random colors
  useEffect(() => {
  const colors = ["#ffc30c", "#01cbff", "#d2202d"];
  const numBalls = 50;
  const balls = [];

  for (let i = 0; i < numBalls; i++) {
    let ball = document.createElement("div");
    ball.classList.add("ball");
    ball.style.background = colors[Math.floor(Math.random() * colors.length)];
    ball.style.left = `${Math.random() * 90}vw`; // Leave space for animation
    ball.style.top = `${Math.random() * 90}vh`; // Leave space for animation
    ball.style.transform = `scale(${Math.random()})`;
     ball.style.width = `${Math.random()}em`;// Ensure minimum size
    ball.style.height = ball.style.width;

    balls.push(ball);
    document.body.append(ball);
  }

  const animations = balls.map((el, i) => {
    const to = {
      x: Math.random() * (i % 2 === 0 ? -5 : 5), // Constrain x translation
      y: Math.random() * 5, // Constrain y translation
    };

    return el.animate(
      [
        { transform: "translate(0, 0)" },
        { transform: `translate(${to.x}rem, ${to.y}rem)` },
      ],
      {
        duration: (Math.random() + 1) * 2000, // random duration
        direction: "alternate",
        fill: "both",
        iterations: Infinity,
        easing: "ease-in-out",
      }
    );
  });

  // Cleanup
  return () => {
    animations.forEach((anim) => anim.cancel());
    balls.forEach((ball) => document.body.removeChild(ball));
  };
}, []);


  return (
    <React.Fragment>
      <ContactFormModal
        setIsModalOpen={setIsModalOpen}
        modalOpen={isModalOpen}
      />
      <div className="company-wrapper">
        <div className="hero-section">
          <motion.div
            className="content"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <h1>Leading Software Development Company</h1>
            <p>
              Unlock the true potential of your business with cutting-edge
              technology. With over two decades of expertise, we deliver
              transformative solutions for global leaders in tech.
            </p>

            <ButtomComp
              click={() => setIsModalOpen(true)}
              buttonTitle="Tell Us About Your Project"
            />
          </motion.div>

          <motion.div
            className="video-gallery"
            onClick={() => {
              // setIsVideo(true);
              // document.body.classList.add("overflow-hidden");
            }}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            <div className="video">
              <motion.img
                src="./2148888277.jpg"
                alt="Client Testimonial"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5 }}
              />
              <div className="play-button">
                <motion.span
                  whileHover={{ scale: 1.3, rotate: 90 }}
                  transition={{ duration: 0.5 }}
                >
                  &#9658;
                </motion.span>
              </div>
            </div>
          </motion.div>
        </div>
        <div className="cms-butons">
          <ButtomComp
            click={() => navigate('/services/artificial-intelligence')}
            buttonTitle="AI and ML Services"
          />
          <ButtomComp
            click={() => navigate('/contactus')}
            buttonTitle="Shasvatam"
          />
        </div>
      </div>
      {isVideo && (
        <React.Fragment>
          <div className="video--shadow"></div>
          <AnimatePresence>
            <motion.div
              className="video--container"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.6 }}
            >
              <div className="video--wrapper">
                <div className="main--video">
                  <video
                    autoPlay
                    controls
                    src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WeAreGoingOnBullrun.mp4"
                    alt="company intro"
                  >
                    <source src="movie.mp4" type="video/mp4" />
                  </video>
                  <FontAwesomeIcon
                    size={24}
                    onClick={() => {
                      setIsVideo(false);
                      document.body.classList.remove("overflow-hidden");
                    }}
                    icon={faClose}
                  />
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CompanySection;
