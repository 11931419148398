import React from "react";
import "./OurSolutions.scss"; // Import your SCSS file
import ImageComp from "./ImageComp";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

// Custom arrow components
const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={`${className} custom-arrow next-arrow`} onClick={onClick}>
      <FontAwesomeIcon icon={faArrowRight} />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={`${className} custom-arrow prev-arrow`} onClick={onClick}>
      <FontAwesomeIcon icon={faArrowLeft} />
    </div>
  );
};

const OurSolutions = ({ services, title, description }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    touchThreshold: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true, // Disable default arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For larger mobile devices or portrait tablets
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // For small mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320, // For extra small mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true, // Enable dots for better navigation on small screens
        },
      },
    ],
  };

  return (
    <section className="our-solutions">
      <h2>{title}</h2>
      <p>{description}</p>
      <div className="services-container">
        <Slider
          {...settings}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
        >
          {services.map((service, index) => (
            <div className="services-wrapper" key={index}>
              <div className="service-item">
                <div>
                  <h3>{service.title}</h3>
                </div>
                <p>{service.description}</p>
              </div>
              {service?.backImage && <ImageComp source={service?.backImage} />}
            </div>
          ))}
        </Slider>
      </div>
      <div className="get-in-touch">
        <h3>Get in Touch</h3>
        <p>
          If you have any questions or want to get started with our services,{" "}
          <a href="mailto:hello@spontaneousenterprises.com">
            contact us today!
          </a>
        </p>
      </div>
    </section>
  );
};

export default OurSolutions;
