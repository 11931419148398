import React from "react";
import "../scss/ImageWithTextSectionGrid.scss";
import ImageComp from "./ImageComp";

const ImageWithTextSectionGrid = ({
    title,
    description,
    source,
    reverse = false
}) => {
  return (
    <div className={`image--with--text-section--grid ${reverse ? 'reverse' : ''}`}>
        <div className="text--section">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
        <div className="image--container">
            <ImageComp height="350px" width="350px" altText={title} source={source} />
        </div>
    </div>
  );
};

export default ImageWithTextSectionGrid;
