import React, { useState } from "react"
import "./../scss/Input.scss"

const TextareaComp = ({
    change,
    name='input',
    isError,
    errorMsg = "This field is required",
    label,
    required = false,
    rows = 5,
    cols = 47,
    isResize
}) => {
    const [inputValue, setInputValue] = useState(null)

    return (
        <React.Fragment>
            <div className="input--wrapper">
                {label && <div>
                    <span className="input--label">{label}</span>
                    {required && <span className="input--asterisk">*</span>}
                </div>}
                <textarea style={{
                    resize: isResize ? 'block' : 'none'
                }} rows={rows} cols={cols} name={name} value={inputValue} onChange={((e) => {
                    setInputValue(e.target.value)
                    change(e)
                })} />
                {isError && <span className="input--error">{errorMsg}</span>}
            </div>
        </React.Fragment>
    )
}

export default TextareaComp