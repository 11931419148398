import React from 'react';
import './WhyChooseUs.scss'; // Assuming you're using SCSS for styling

const WhyChooseUs = () => {
  return (
    <div className="why-choose-section">
      <div className="why-choose-content">
        <div className="why-choose-left">
          <img src="./success.png" alt="Spontaneous Enterprises" />
        </div>
        <div className="why-choose-right">
          <h2>Why Choose Spontaneous To Stimulate Your Success?</h2>
          <p className="intro-text">
            Spontaneous Enterprises is a boutique software development and digital marketing company. We have decades of collective experience building innovative software and strategies that drive traffic and stimulate your business growth.
          </p>
          <div className="features-grid">
            <div className="feature-item">
              <div className="feature-icon">🎨</div>
              <h3>Innovative Design for Every Client</h3>
              <p>Tailored solutions that meet unique business needs.</p>
            </div>
            <div className="feature-item">
              <div className="feature-icon">🤖</div>
              <h3>Automation in Everything</h3>
              <p>Streamlining processes with cutting-edge technology.</p>
            </div>
            <div className="feature-item">
              <div className="feature-icon">📞</div>
              <h3>Clear Communications</h3>
              <p>Transparent and consistent dialogue with our clients.</p>
            </div>
            <div className="feature-item">
              <div className="feature-icon">⚙️</div>
              <h3>Optimized Process</h3>
              <p>Efficiency and effectiveness in every project phase.</p>
            </div>
          </div>
          <a href="/aboutus" className="discover-link">Want to know how we started? Discover</a>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
