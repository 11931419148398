import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Homepage from "./components/Homepage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Blogs from "./components/Blogs";
import HeaderMenu from "./components/Utils/Header";
import Footer from "./components/Utils/Footer";
import BlogPage from "./components/BlogPage";
import ServicePage from "./components/ServicePage";
import ContactUs from "./components/ContactUs";
import AboutUs from "./components/AboutUs";
import TechnologyPage from "./components/TechnologyPage";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const colors = ["#ffc30c", "#01cbff", "#d2202d"]; // Colors for the rings
let colorIndex = 0; // Initialize color index

function LoadingWrapper({ children }) {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => setLoading(false), 500); // Simulate loading delay
    return () => clearTimeout(timeout); // Cleanup timeout
  }, [location]);

  return (
    <>
      {loading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}
      {children}
    </>
  );
}

function App() {
  const tawkMessengerRef = useRef();

  const handleMinimize = () => {
    tawkMessengerRef.current.minimize();
  };

  // Mouse ring effect
  useEffect(() => {
    const createRing = (x, y) => {
      const ring = document.createElement("div");
      ring.classList.add("cursor-ring");

      // Set the position of the ring at the cursor's position
      ring.style.left = `${x + window.scrollX}px`;
      ring.style.top = `${y + window.scrollY}px`;

      // Set the border color using the colors array
      ring.style.borderColor = colors[colorIndex];

      // Update the color index for the next ring
      colorIndex = (colorIndex + 1) % colors.length;

      // Append the ring to the document
      document.body.appendChild(ring);

      // Remove the ring after the animation completes (0.8s)
      setTimeout(() => {
        ring.remove();
      }, 800); // Matches the CSS animation duration
    };

    const handleMouseMove = (event) => {
      // Throttle the ring creation to avoid lag
      requestAnimationFrame(() => {
        createRing(event.clientX, event.clientY);
      });
    };

    // Track mouse movement
    document.addEventListener("mousemove", handleMouseMove);

    // Cleanup on component unmount
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <Router>
      <LoadingWrapper>
        <HeaderMenu />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog/:id" element={<BlogPage />} />
          <Route path="/services/:service" element={<ServicePage />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/technology/:technology" element={<TechnologyPage />} />
        </Routes>
        <Footer />
        <TawkMessengerReact
          propertyId="672df2442480f5b4f59ac955"
          widgetId="1ic5nkmvg"
          ref={tawkMessengerRef}
        />
      </LoadingWrapper>
    </Router>
  );
}

export default App;
