import React from 'react';
import "../scss/ServiceSection.scss"

const ServiceSection = ({
  title,
  description,
  image
}) => {
  return (
    <section className="service-section">
      <div className="content-container">
        <div className="text-content">
          <h1>{title}</h1>
          <p>
            {description}
          </p>
          <div className="cta-buttons">
            <a href="/contactus" className="cta-button">Get Inquiry</a>
            <a href="/aboutus" className="cta-button outline">About Us</a>
          </div>
        </div>
        <div className="image-content">
          <img src={image} alt="React Native Development" />
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
