import React from "react";
import "./AboutUs.scss"; // Import the CSS file

const WeAreSpontaneous = () => {
  return (
    <div className="about-us-container">
      <h1 className="about-us-title">We're Spontaneous In Everything We Do!</h1>
      <p className="about-us-subtitle">
        Here’s how we made it through the journey.
      </p>
      <div className="about-us-stats">
        <div className="stat-item">
          <div className="stat-icon yellow">
            <span className="star">&#9733;</span>
          </div>
          <div className="stat-info">
            <p className="stat-number">7+</p>
            <p className="stat-label">Years of Service</p>
          </div>
        </div>
        <div className="stat-item">
          <div className="stat-icon blue">
            <span className="star">&#10040;</span>
          </div>
          <div className="stat-info">
            <p className="stat-number">60+</p>
            <p className="stat-label">Projects Delivered</p>
          </div>
        </div>
        <div className="stat-item">
          <div className="stat-icon yellow">
            <span className="star">&#9734;</span>
          </div>
          <div className="stat-info">
            <p className="stat-number">50+</p>
            <p className="stat-label">Happy Customers</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeAreSpontaneous;
