import React from 'react';
import BlogCard from './BlogCard.js';
import './BlogList.scss';
import { useNavigate } from 'react-router-dom';

const BlogList = ({ blogs }) => {
    const navigate = useNavigate()

    return (
        <div className="blog-list">
            {blogs.map((blog, index) => (
                <BlogCard
                    click={() => navigate(`/blog/${blog.id}`)}
                    key={index}
                    image={blog?.image} 
                    title={blog?.title}
                    description={blog?.description}
                    readTime={blog?.readTime}
                    date={blog?.date}
                />
            ))}
        </div>
    );
};

export default BlogList;
