
import React from 'react'
import "../scss/Card.scss"

const Card = ({
    children
}) => {

    return (
            <div className='card--wrapper'>
                <div className='card--comp'> 
                    {children}
                </div>
            </div>
    )
}

export default Card