import React from 'react';

const generateId = (text) => {
  return text.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
};

const TableOfContents = ({ content }) => {
  const headings = content.filter(block => block.type === 'heading');

  return (
    <div className="table-of-contents">
      <h2>Table of Contents</h2>
      <ul>
        {headings.map((heading, index) => (
          <li key={index} className={`toc-level-${heading.level}`}>
            <a href={`#${generateId(heading.children[0].text)}`}>
              {heading.children[0].text}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TableOfContents;
