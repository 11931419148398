import React, {forwardRef} from 'react'
import "./TabItem.scss"

const TabItem = forwardRef(({
    tabName,
    click = () => null,
    isPanel = false,
    isSelected = false,
    mouseOver = () => null,
    mouseLeave = () => null
}, ref) => {

    return (
    <React.Fragment>
        <div ref={ref} onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={click} className={`tab--item ${isPanel ? "tab--panel--item" : "tab--item--menu"} ${isSelected ? "tab--panel--selected" : ""}`}>
            <span className='tab--span'>{tabName}</span>
        </div>
    </React.Fragment>)
})

export default TabItem