import React from "react";
import "../scss/BlogStrip.scss"

interface HeaderComponentProps {
  title: string;
  subtitle: string;
}

const BlogStrip: React.FC<HeaderComponentProps> = ({ title, subtitle }) => {
  return (
    <div className="header-component">
      <h1 className="header-title">{title}</h1>
      <p className="header-subtitle">{subtitle}</p>
    </div>
  );
};

export default BlogStrip;
