import React, { useState } from 'react';
import '../scss/OurStory.scss';
import ButtomComp from './ButtonComp';
import ContactFormModal from './ContactFormModal';

const OurStory = () => {
  const [isModal, setIsmodal] = useState(false)

  return (
    <section className="about-us">
    <ContactFormModal setIsModalOpen={setIsmodal} modalOpen={isModal} />
      <div className="about-us__content">
        <h2 className="about-us__heading">Your Tech Partner Since 2017 - Know Our Story</h2>
        <p className="about-us__intro">
          Our name says it all about us! We are <span className="highlight">Spontaneous Enterprises</span>!
        </p>
        <p className="about-us__text">
          My name is <span className="highlight">Chirag</span> (CEO of the company), and I’d like to tell you about this amazing journey.
        </p>
        <p className="about-us__text">
          It was in the summer of 2017 when I was enjoying my dinner with my family. My brother, <span className="highlight">Vishal</span>, my little champ, recently graduated in Computer Engineering. He is a smart guy when it comes to software development, and he’s always interested in starting his own venture.
        </p>
        <p className="about-us__text">
          He asked me what if we brothers established our company. I was quite amazed. However, I even dreamt of starting my venture. And that moment, I said, <span className="highlight">YES!</span>
        </p>
        <p className="about-us__text">
          Vishal and I discussed our services for a couple of hours. And we knew what we could offer the best was the custom business software. Our business strategy was prepared the next day, and I quit my job real quick within some days.
        </p>
        <p className="about-us__text">
          I wonder if my brother Vishal hadn’t inspired me to start this venture, it would still be a dream.
        </p>
        <blockquote className="about-us__quote">
          Many times, it happens in life when all you need is to spark a conversation and share an idea to start something great! For me, it was our company – <span className="highlight">Spontaneous Enterprises</span>!
        </blockquote>
        <p className="about-us__call-to-action">
          What about you? Don’t wait any longer! If you have any ideas, we can talk about them and create something helpful together for our audience!
        </p>
        <div className='about-us__button'>
            <ButtomComp click={() => setIsmodal(true)} buttonTitle="Spart a conversation" />
        </div>
      </div>
    </section>
  );
};

export default OurStory;
