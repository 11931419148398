// src/components/TechnologyPage.js

import React, { useState } from "react";
import "./scss/TechnologyPage.scss"; // Import custom CSS for styling
import FAQSection from "./Utils/FAQSection";
import BlogArticle from "./Utils/BlogArticle";
import TestimonialCarousel from "./Utils/TestimonialCarousel";
import StatsSection from "./Utils/StatsSection";
import TechnologiesSection from "./Utils/TechnologySection";
import OurSolutions from "./Utils/OurSolutions";
import ServiceSection from "./Utils/ServiceSection";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getTechPage } from "../services/api";
import { IMAGE_URL } from "../services/api";
import { getBlogsByCatName } from "../services/api";

const TechnologyPage = () => {
  let { technology } = useParams();
  console.log(technology, "technology");
  const [pageData, setPageData] = useState();
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const faqs = [
    {
      question: "Why should we choose React for our web application?",
      answer:
        "React offers a component-based architecture that promotes reusability, making development faster and more efficient. Its virtual DOM enhances performance, providing a smoother user experience.",
    },
    {
      question: "Is React suitable for large-scale applications?",
      answer:
        "Yes, React is highly scalable and is used by large companies such as Facebook and Instagram. Its ecosystem supports the development of complex applications with ease.",
    },
    {
      question: "What are the benefits of using React for our project?",
      answer:
        "Using React can lead to faster development cycles, better performance due to its virtual DOM, and a strong community that provides ample resources, libraries, and support.",
    },
    {
      question: "Can we maintain our application easily with React?",
      answer:
        "Absolutely! React’s component-based structure allows for easy maintenance and updates. Changes can be made in individual components without affecting the entire application.",
    },
    {
      question: "How does React handle SEO for our web application?",
      answer:
        "While React is a client-side library, it can be configured for server-side rendering (SSR) using frameworks like Next.js, improving SEO performance by ensuring that search engines can index the content effectively.",
    },
    {
      question: "What is the learning curve for our developers with React?",
      answer:
        "React has a gentle learning curve, especially for developers familiar with JavaScript. Its extensive documentation and community support make it easier for teams to get up to speed.",
    },
    {
      question: "How does React compare to other front-end frameworks?",
      answer:
        "React is often favored for its flexibility and performance. Unlike some frameworks, it allows developers to integrate with other libraries and frameworks seamlessly, providing a more tailored solution.",
    },
    {
      question: "Can we integrate React with our existing technology stack?",
      answer:
        "Yes, React is designed to integrate well with various back-end technologies and APIs, making it a versatile choice for modern web development.",
    },
    {
      question:
        "What are the key features we should include in our application?",
      answer:
        "Key features depend on your specific needs but commonly include user authentication, responsive design, interactive UI components, and robust data handling capabilities.",
    },
    {
      question: "How long will it take to develop our application?",
      answer:
        "The development timeline varies based on project complexity and requirements. A typical web application can take anywhere from a few weeks to several months.",
    },
    {
      question: "What is the estimated cost for the development project?",
      answer:
        "Development costs depend on factors such as project scope, features, and technology stack. We can provide a detailed estimate after discussing your specific requirements.",
    },
    {
      question:
        "How do you ensure the quality and performance of the application?",
      answer:
        "We implement a combination of automated testing, code reviews, and performance optimizations throughout the development process to ensure a high-quality end product.",
    },
    {
      question: "What happens if we need changes after the project starts?",
      answer:
        "We can accommodate changes through a change request process, but it’s important to discuss how these changes may affect timelines and costs.",
    },
    {
      question: "What post-launch support will you provide?",
      answer:
        "We offer various support options after launch, including maintenance, updates, and bug fixes to ensure your application runs smoothly.",
    },
    {
      question: "How do you handle data security in the application?",
      answer:
        "We prioritize data security by following best practices, including secure coding techniques, regular security audits, and compliance with relevant regulations.",
    },
  ];

  const getRelatedBlogs = async (categories) => {
    try {
      const relatedBlogs = await getBlogsByCatName(categories);
      if (relatedBlogs?.length > 0) {
        const transformedBlogs = relatedBlogs?.map((blog) => {
          return {
            id: blog.documentId,
            image: blog.blog_image.url,
            title: blog.blog_title,
            description: blog.description,
            readTime: blog.duration,
            date: blog.postDate,
          };
        });
        console.log(transformedBlogs, "transformedBlogs");
        setRelatedBlogs(transformedBlogs);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (technology) {
      const getPageInfo = async () => {
        const pageData = await getTechPage(technology);
        console.log(pageData, "pageData");
        if (pageData?.category && pageData?.category?.category_name) {
          getRelatedBlogs(pageData?.category.category_name);
        }
        setPageData(pageData);
      };

      getPageInfo();
    }
  }, [technology]);

  const getComponents = (component) => {
    console.log(component, "comp");
    switch (component.__component) {
      case "solutions.our-solutions":
        return (
          <OurSolutions
            title={component.title}
            description={component.desc}
            services={
              component.solution?.length > 0
                ? component.solution?.map((item) => ({
                    title: item.solutionName,
                    description: item.solutionDesc,
                    backImage: item.image?.url,
                  }))
                : []
            }
          />
        );
      case "technologies.dev-technologies":
        return (
          <TechnologiesSection
            technologies={component.techStack?.map((item) => ({
              name: item.techName,
              logo: item.tech_image?.url,
            }))}
            title={component.techTitle}
            description={component.techDesc}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {pageData && (
        <>
          <ServiceSection
            image={pageData?.header?.image?.url}
            description={pageData?.header?.description}
            title={pageData?.header?.title}
          />
          {pageData?.techonology_info?.map((item) => getComponents(item))}
          <StatsSection />
          {/* <TestimonialCarousel /> */}
          {relatedBlogs?.length > 0 && (
            <BlogArticle blogs={relatedBlogs} title="ReactJS Latest Insights" />
          )}
          <FAQSection faqTitle={"Frequently Asked Questions"} faqs={faqs} />
        </>
      )}
    </>
  );
};

export default TechnologyPage;
