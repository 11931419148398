import React from "react";
import Card from "./Utils/Card";
import "./scss/ContactUs.scss";
import TextInput from "./Utils/TextInput";
import TextareaComp from "./Utils/Textarea";
import ButtomComp from "./Utils/ButtonComp";
import ClientShowCase from "./Utils/ClientShowCase";
import FAQSection from "./Utils/FAQSection";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    message: "",
  });

  const contactUsFaqs = [
    {
      question: "How can I contact your support team?",
      answer:
        "You can reach our support team by filling out the contact form on this page or by emailing us directly at hello@spontaneousenterprise.com We aim to respond within 24 hours.",
    },
    {
      question: "What are your business hours?",
      answer:
        "Our business hours are Monday to Friday, 9:00 AM to 6:00 PM (GMT). Feel free to contact us during these hours for any inquiries or support.",
    },
    {
      question: "How quickly can I expect a response?",
      answer:
        "We strive to respond to all inquiries within 24-48 hours. For urgent matters, please indicate the urgency in your message and we will prioritize accordingly.",
    },
    {
      question: "Can I schedule a meeting with your team?",
      answer:
        "Yes, we are happy to schedule meetings to discuss your project in detail. Please use the contact form to request a meeting, and one of our representatives will get in touch to arrange a convenient time.",
    },
    {
      question: "What information should I provide when contacting you?",
      answer:
        "When reaching out, please provide details about your project or inquiry, including your goals, timelines, and any specific requirements. This will help us better understand your needs and provide a more informed response.",
    },
    {
      question: "Do you offer support after business hours?",
      answer:
        "For critical issues, we provide limited after-hours support. Please include the nature of your issue when contacting us, and we'll do our best to address it as soon as possible.",
    },
    {
      question: "Can I visit your office for an in-person consultation?",
      answer:
        "We offer both virtual and in-person consultations depending on your location and preference. Please contact us to schedule a meeting, and we will provide details for an in-person visit if available.",
    },
  ];

  const onValueChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        "https://dev-cmsadmin.spontaneousenterprise.com/api/contactus",
        formData
      );
      if (response.data.message) {
        setNotification({
          message: "Email sent successfully!",
          type: "success",
        });
        navigate("/");
      }
    } catch (error) {
      setNotification({
        message: "Failed to send email. Please try again later.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="contact-intro">
        <h2 className="contact-intro__title">
          Ready to Create the Next Big Thing?
        </h2>
        <h3 className="contact-intro__subtitle">
          We’re passionate about turning your challenges into tech innovations.
          Contact us, and let’s start coding your success story.
        </h3>
      </div>

      <div className="main--image"></div>

      <div className="contact--from">
        <Card>
          <div className="contact--wrapper">
            <div className="contact--header">
              <div className="title">Powerful Applications for your Niche</div>
              <div className="subtitle">
                Confidently design, implement, test and maintain software, web
                and mobile applications with Radixweb expertise.
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="contact--details">
                <TextInput
                  required={true}
                  isError={false}
                  label="Name"
                  name="name"
                  change={onValueChange}
                />
                <TextInput
                  required={true}
                  isError={false}
                  label="Company"
                  name="company"
                  change={onValueChange}
                />
                <TextInput
                  required={true}
                  isError={false}
                  label="Email"
                  name="email"
                  change={onValueChange}
                />
                <TextInput
                  required={true}
                  isError={false}
                  label="Phone"
                  name="phone"
                  change={onValueChange}
                />
                <TextareaComp
                  isError={false}
                  required={true}
                  change={onValueChange}
                  label="Message"
                  name="message"
                  isResize={false}
                />
              </div>
              <div className="button--container">
                <ButtomComp
                  type="submit"
                  disabled={loading}
                  buttonTitle={loading ? "Sending..." : "Send Message"}
                />
              </div>
            </form>
          </div>
        </Card>

        <ClientShowCase />
        <FAQSection
          faqs={contactUsFaqs}
          faqTitle="Frequently Asked Questions"
        />
        <div className="map-section">
          <h2>Find Us Here</h2>
          <div className="map-flex">
            <div>
              <h4>Register Address</h4>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.0740349977887!2d72.6280888753127!3d22.98430487920056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e87ad2b2b57ef%3A0x9b89554cbc895de0!2sSpontaneous%20Enterprise%20(OPC)%20Pvt.%20Ltd!5e0!3m2!1sen!2sin!4v1728301767558!5m2!1sen!2sin"
                width="500"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div>
              <h4>Corporate Address</h4>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d918.4496294917553!2d72.6542700267304!3d22.957646217612535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e890060ab26e5%3A0xf55716ae4f12cf3a!2sSpontaneous%20Enterprise%20OPC%20Pvt.%20Ltd!5e0!3m2!1sen!2sin!4v1739190136578!5m2!1sen!2sin"
                width="500"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactUs;
