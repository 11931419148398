import React, { useState, useEffect } from "react";
import Slider from 'react-slick';
import './ClientShowCase.scss'; // Import your SCSS file
import { fetchClients } from "../../services/api";

const ClientShowCase = () => {

    const [clients, setClients] = useState(null)

  const getClients = async () => {
    try {
      const response = await fetchClients()
      console.log(response,'res')
      setClients(response)
    } catch(err) {
      console.error(err)
    }
  }
  useEffect(() => {
    getClients()
  }, [])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    touchThreshold: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Adjust autoplay speed (in milliseconds)
    arrows: false, // Show navigation arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="client-showcase">
      <div className="client-info">
        <h2>{clients?.title}</h2>
        <p>{clients?.description}</p>
      </div>
      {clients?.clientImages?.length > 0 && <div className="client-logos">
        <Slider {...settings}>
          {clients?.clientImages.map((logo, index) => (
            <div className="logo-container" key={index}>
              <img src={'https://dev-cmsadmin.spontaneousenterprise.com' +logo?.img?.url} alt={logo?.name} />
            </div>
          ))}
        </Slider>
      </div>}
    </section>
  );
};

export default ClientShowCase;
