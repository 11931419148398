import React from 'react';
import '../scss/StatCard.scss'; // Optional SCSS for styling

const StatCard = ({ value, description }) => {
  return (
    <div className="stat-card">
      <h2>{value}</h2>
      <p>{description}</p>
    </div>
  );
};

export default StatCard;
