import React, { useEffect, useState } from "react";
import { fetchHomepage, getTechnologies } from "../services/api";
import Card from "./Plugins/Card";
import { getCompanyInfo } from "../services/api";
import Technologies from "./Utils/Technologies";
import AboutUs from "./Utils/AboutUsHome";
import WhyChooseUs from "./Utils/WhyChooseUs";
import ClientShowCase from "./Utils/ClientShowCase";
import OurSolutions from "./Utils/OurSolutions";
import ClientReviews from "./Utils/ClientReviews";
import CompanySection from "./Utils/CompanySection";
import FAQSection from "./Utils/FAQSection";
import { IMAGE_URL } from "../services/api";

const HomePage = () => {
  const [homepage, setHomepage] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [technologies, setTechnologies] = useState();

  const faqs = [
    {
      question: "What software development services do you offer?",
      answer:
        "We offer a wide range of software development services including custom software development, mobile app development, web application development, cloud solutions, software product development, and maintenance and support.",
    },
    {
      question: "How do you ensure the quality of your software products?",
      answer:
        "Our software development process includes thorough testing at every stage, including unit testing, integration testing, system testing, and user acceptance testing. We also follow industry best practices and standards to ensure high-quality deliverables.",
    },
    {
      question: "What methodologies do you use in software development?",
      answer:
        "We primarily use Agile methodologies like Scrum and Kanban, but we are also experienced in Waterfall, DevOps, and Hybrid models. Our approach depends on the project requirements and client preferences.",
    },
    {
      question: "Can you handle legacy software modernization?",
      answer:
        "Yes, we specialize in modernizing legacy systems. We analyze your existing software, identify areas for improvement, and migrate it to modern platforms while ensuring minimal disruption to your business operations.",
    },
    {
      question: "How do you manage project timelines and deadlines?",
      answer:
        "We use project management tools like JIRA and Trello to track progress, manage timelines, and ensure that all tasks are completed on schedule. Regular updates and communication with clients help us stay aligned with project goals.",
    },
    {
      question: "What is your approach to data security?",
      answer:
        "We prioritize data security in all our projects. Our practices include encryption, secure coding standards, regular security audits, and compliance with industry regulations like GDPR and HIPAA.",
    },
    {
      question: "Do you offer post-launch support and maintenance?",
      answer:
        "Yes, we provide ongoing support and maintenance services after the software is launched. This includes bug fixing, performance optimization, and updates to ensure the software remains up-to-date and secure.",
    },
  ];

  const services = [
    {
      title: "Software Development",
      description:
        "Our software development services unlock the ability to build new tools and solutions, revolutionize your business operations, automate tedious tasks, and maximize efficiency in every process. Get started today!",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-code"
        >
          <path d="M16 18l6-6-6-6M8 18l-6-6 6-6" />
        </svg>
      ),
      backImage: "software development.png",
    },
    {
      title: "IT Consulting",
      description:
        "With the right consulting by our subject matter experts, you can take control of business operations in a way that won’t break the bank and create mental headspace for everyone involved. Invest in IT consulting today!",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-briefcase"
        >
          <rect x="2" y="7" width="20" height="14" rx="2" ry="2" />
          <path d="M2 7h20M12 11v6" />
        </svg>
      ), // Replace with your icon
      backImage: "IT consulting 300 X 500 px.png",
    },
    {
      title: "DevOps Consulting",
      description:
        "Accelerate your software development lifecycle with our expert DevOps consulting services. We streamline deployment processes, enhance collaboration between teams, and implement continuous integration and delivery (CI/CD) practices to ensure faster, more reliable releases. Transform your development operations with DevOps today!",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-settings"
        >
          <circle cx="12" cy="12" r="3" />
          <path d="M19.4 15a7 7 0 0 1-1.4 1.9l1.6 1.6a1 1 0 0 1-1.4 1.4l-1.6-1.6a7 7 0 0 1-1.9 1.4l-.4 1.6a1 1 0 0 1-1 .8h-2a1 1 0 0 1-1-.8l-.4-1.6a7 7 0 0 1-1.9-1.4l-1.6 1.6a1 1 0 0 1-1.4-1.4l1.6-1.6a7 7 0 0 1-1.4-1.9l-1.6-.4a1 1 0 0 1-.8-1v-2a1 1 0 0 1 .8-1l1.6-.4a7 7 0 0 1 1.4-1.9l-1.6-1.6a1 1 0 0 1 1.4-1.4l1.6 1.6a7 7 0 0 1 1.9-1.4l.4-1.6a1 1 0 0 1 1-.8h2a1 1 0 0 1 1 .8l.4 1.6a7 7 0 0 1 1.9 1.4l1.6-1.6a1 1 0 0 1 1.4 1.4l-1.6 1.6a7 7 0 0 1 1.4 1.9l1.6.4a1 1 0 0 1 .8 1v2a1 1 0 0 1-.8 1l-1.6.4a7 7 0 0 1-1.4 1.9z" />
        </svg>
      ),
      backImage: "DevOps 300 X 500 px.png",
    },
    {
      title: "Digital Marketing",
      description:
        "Our market-driven strategies can help you get more online exposure and boost conversions effortlessly. We also ensure that users have an enjoyable experience while interacting with your website. Contact us for digital marketing strategies!",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-bar-chart"
        >
          <rect x="3" y="12" width="4" height="9" />
          <rect x="10" y="8" width="4" height="13" />
          <rect x="17" y="3" width="4" height="18" />
        </svg>
      ), // Replace with your icon
      backImage: "digital marketing 300 X 500 px.png",
    },
    {
      title: "Emerging Technologies",
      description:
        "Explore unexpected possibilities with our AI and machine learning services. Also, enhance your business’s cyber security with top-notch security measures to detect potential threats in advance. Step ahead into an exciting new future with us today!",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-processor"
        >
          <rect x="2" y="2" width="20" height="20" rx="2" ry="2" />
          <path d="M6 6v12M6 6h12M18 6v12M6 18h12" />
        </svg>
      ), // Replace with your icon
      backImage: "emerging technologies.png",
    },
    {
      title: "Cloud Technology",
      description:
        "Revolutionize your business using cloud technology like AWS, Azure, Google Cloud, and many more. It keeps your data secure, facilitates migrations across different systems, and improves efficiency in operations. Unlock Seamless Clouding today!",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-cloud"
        >
          <path d="M20 17.39A4.61 4.61 0 0 0 15.39 12H6.61A4.61 4.61 0 0 0 2 16.61 4.61 4.61 0 0 0 6.61 20h13.89a4.61 4.61 0 0 0 4.61-4.61" />
          <path d="M16 13h-6v6h6v-6z" />
        </svg>
      ), // Replace with your icon
      backImage: "cloud computing image 300 × 500 px.png",
    },
  ];

  const getTechnologiesData = async () => {
    const data = await getTechnologies();
    if (data?.length > 0) {
      const transformedObject = data?.reduce((acc, item) => {
        // Extract the category (tech_type.technology_category) and format it to uppercase
        const category = String(
          item.tech_type.technology_category
        ).toUpperCase();

        // Map through the technologies to extract relevant details
        const technologies = item.technologies?.map((techItem) => ({
          name: techItem.tech_name,
          path: techItem.page_url,
          logo: IMAGE_URL + techItem?.tech_image?.url, // Assuming tech_image is part of techItem
        }));

        // Add the category and technologies to the accumulator object
        if (technologies) {
          acc[category] = technologies;
        }

        return acc;
      }, {});

      console.log(transformedObject,'odjsd')
      setTechnologies(transformedObject);
    }
  };

  useEffect(() => {
    const getHomepage = async () => {
      const data = await getCompanyInfo();
      setCompanyInfo(data);
    };
    getTechnologiesData();
    getHomepage();
  }, []);

  return (
    <React.Fragment>
      <CompanySection />
      <div className="company--clients--wrapper">
        <ClientShowCase />
        <WhyChooseUs />
      </div>
      <OurSolutions
        title="Our Solutions"
        description="The team at Spontaneous Enterprises is always on the go to think new and
        put their hands on creative projects to produce high-value masterpieces
        that stand out from the rest!"
        services={services}
      />
      <div className="Client--and--technologies--wrapper">
        <Technologies
          techItems={technologies}
          title="Technologies We Work on"
        />
        <ClientReviews />
      </div>
      <FAQSection faqTitle="Software Development FAQs" faqs={faqs} />
    </React.Fragment>
  );
};

export default HomePage;
