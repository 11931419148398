import React, { useState } from "react";
import "../scss/ContactFormModal.scss";
import axios from "axios";
import ButtomComp from "./ButtonComp";

const ContactFormModal = ({ modalOpen, setIsModalOpen }) => {
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        "https://dev-cmsadmin.spontaneousenterprise.com/api/contactus",
        formData
      );
      if (response.data.message) {
        setNotification({
          message: "Email sent successfully!",
          type: "success",
        });
      }
    } catch (error) {
      setNotification({
        message: "Failed to send email. Please try again later.",
        type: "error",
      });
    } finally {
      setLoading(false);
      setIsModalOpen(false); // Close the modal after submission
      setFormData({
          name: "",
          company: "",
          email: "",
          phone: "",
          message: "",
        });
    }
  };

  return (
    <div>
      {modalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button
              onClick={() => setIsModalOpen(false)}
              className="close-button"
            >
              &times;
            </button>
            <h2>Contact Us</h2>
            {notification.message && (
              <div className={`notification ${notification.type}`}>
                {notification.message}
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="form-group-pair">
                <div className="form-group">
                  <label>Name*</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Your name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Company*</label>
                  <input
                    type="text"
                    name="company"
                    placeholder="Your company"
                    value={formData.company}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group-pair">
                <div className="form-group">
                  <label>Email*</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Your email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Phone*</label>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Your phone number"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Message*</label>
                <textarea
                  name="message"
                  placeholder="Your message"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <ButtomComp
                type="submit"
                disabled={loading}
                buttonTitle={loading ? "Sending..." : "Send Message"}
              />
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactFormModal;
