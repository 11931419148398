import React from 'react';
import '../scss/TechnologyCard.scss'; // Optional SCSS for styling

const TechnologyCard = ({ logo, name }) => {
  return (
    <div className="tech-card">
      <img src={logo} alt={name} className="tech-logo" />
      <p>{name}</p>
    </div>
  );
};

export default TechnologyCard;
