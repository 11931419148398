import axios from "axios";

export const API_URL = "https://dev-cmsadmin.spontaneousenterprise.com/api"; // Update with your Strapi API URL
export const IMAGE_URL = "https://dev-cmsadmin.spontaneousenterprise.com"

const axiosInstance = axios.create({
  baseURL: API_URL, // Set your API base URL
  timeout: 1000000, // Set a timeout of 5 seconds (5000 milliseconds)
});

export const fetchHomepage = async () => {
  const response = await axiosInstance.get(`${API_URL}/home-pages`); // Update endpoint as necessary
  return response.data.data;
};

export const getCompanyInfo = async () => {
  try {
    const response = await axiosInstance.get(`${API_URL}/company-data?populate=*`); // Update endpoint as necessary
    return response.data.data;
  } catch (error) {
    console.error(error, "error");
  }
};

export const getServices = async () => {
  try {
    const response = await axiosInstance.get(`${API_URL}/services?populate=*`); // Update endpoint as necessary
    return response.data.data;
  } catch (error) {
    console.error(error, "error");
    return error
  }
};

export const getBlogsByCatName = async (categories) => {
  try {
    const response = await axiosInstance.post(`${API_URL}/getBlogsByCatName/`, {
      categories: categories
    }); // Update endpoint as necessary
    return response.data;
  } catch (error) {
    console.error(error, "error");
    return error
  }
};

export const fetchBlogs = async () => {
  try {
    const response = await axiosInstance.get(`${API_URL}/Blogs?populate=*`); // Update endpoint as necessary
    return response.data.data;
  } catch (error) {
    console.error(error, "error");
  }
};

export const fetchBlogsByid = async (id) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/Blogs/${id}?populate[authorInfo][populate]=*`); // Update endpoint as necessary
    return response.data.data;
  } catch (error) {
    console.error(error, "error");
  }
};

export const fetchRElatedBlogsById = async (id) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/Blogs/${id}/related?populate=*`); // Update endpoint as necessary
    return response.data;
  } catch (error) {
    console.error(error, "error");
  }
};


export const getPageData = async (pageName) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/service-pages/${pageName}`, {
      params: {
        populate: '*'
      },
    });
    console.log(response.data, 'Page data response');
    return response.data;
  } catch (error) {
    console.error(error, "error");
    return error;
  }
};

export const fetchClients = async () => {
  try {
    const response = await axiosInstance.get(`${API_URL}/clients-data?populate[clientImages][populate]=*`); // Update endpoint as necessary
    console.log(response, 'response')
    return response.data.data;
  } catch (error) {
    console.error(error, "error");
  }
};

export const getTechnologies = async () => {
  try {
    const response = await axiosInstance.get(`${API_URL}/technologies?populate[technologies][populate]=*&populate[tech_type][populate]=*`); // Update endpoint as necessary
    console.log(response, 'response')
    return response.data.data;
  } catch (error) {
    console.error(error, "error");
  }
};

export const getTechPage = async (slug) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/technologies-pages/${slug}`); // Update endpoint as necessary
    console.log(response, 'response')
    return response.data;
  } catch (error) {
    console.error(error, "error");
  }
};