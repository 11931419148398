import React from "react";
import "./CustomSolution.scss"; // Ensure this file includes the SCSS styling
import ButtomComp from "./ButtonComp";
import { useNavigate } from "react-router-dom";

const CustomSolution = () => {
  const Navigate = useNavigate()
  
  return (
    <div>
      {/* Existing sections */}
      <div className="menu--section menu--fullwidth">
        {/* Your previous sections here */}
      </div>

      {/* New enterprise solution section */}
      <div className="menu--section menu--enterprise">
        <div className="enterprise--content">
          <h2 className="enterprise--title">Need a Custom Enterprise Solution?</h2>
          <p className="enterprise--text">
            Contact our team to discuss a solution tailored to you and your team’s needs.
          </p>
        </div>
          <ButtomComp click={() => Navigate('/contactus')} buttonTitle="Contact Us" />
      </div>
    </div>
  );
};

export default CustomSolution;
