import React, { useEffect, useState } from "react";
import "./scss/BlogPage.scss";
import { fetchRElatedBlogsById, fetchBlogsByid } from "../services/api";
import { useParams } from "react-router-dom";
import DOMPurify from 'dompurify';
import TableOfContents from "./TableOfContents"; // Import the TOC component
import GetInTouch from "./GetIntouch";
import BlogArticle from "./Utils/BlogArticle";

const BlogPage = () => {
  const [blogsData, setBlogsData] = useState([]);
  let { id } = useParams();
  const [relatedBlogs, setRelatedBlogs] = useState([])

  useEffect(() => {
    const fetchBlog = async () => {
      const data = await fetchBlogsByid(id);
      setBlogsData(data);
      if (data?.id) {

        const relatedBlogs = await fetchRElatedBlogsById(data?.id);
        if (relatedBlogs?.length > 0) {
          const transformedBlogs = relatedBlogs?.map(blog => {
            return {
              id: blog.documentId,
              image: blog.blog_image.url,
              title: blog.blog_title,
              description: blog.description,
              readTime: blog.duration,
              date: blog.postDate
            };
          });
          console.log(transformedBlogs, 'transformedBlogs')
          setRelatedBlogs(transformedBlogs)
        }
      }
    };

    fetchBlog();
  }, [id]);

  const blogContent = DOMPurify.sanitize(blogsData?.blog_content);

  return (
    <div className="header-container">
      <div className="header-top">
        <span className="main--category--name">Application Development</span>
        <br />
        <span className="main--date">
          Published: {blogsData?.postDate}
        </span>
      </div>
      <h1 className="header-title">{blogsData?.blog_title}</h1>
      <div className="author-info">
        <img
          src={blogsData?.authorInfo?.authorImg?.url}
          alt="Author Image"
          width="50"
        />
        <div>
          <span className="author-name">{blogsData?.authorInfo?.authorName}</span>
          <div className="author-description">
            {blogsData?.authorInfo?.description}
          </div>
        </div>
      </div>
      <div className="expertise-tags">
        {blogsData?.categories?.data?.length > 0 &&
          blogsData.attributes.categories.data.map((item) => (
            <span className="expertise-tag" key={item.id}>
              {item.attributes.category_name}
            </span>
          ))}
      </div>

      {blogContent && (
        <div className="blog--info">
          <div dangerouslySetInnerHTML={{
            __html: blogContent
          }} className="blog--content">
          </div>
          <div className="blog--other--content">
            {/* <TableOfContents content={blogContent} /> */}
            <GetInTouch />
          </div>
        </div>
      )}

      {relatedBlogs?.length > 0 && <BlogArticle title="Related Blogs" blogs={relatedBlogs} />}
    </div>
  );
};

export default BlogPage;
