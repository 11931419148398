import React from "react"
import './TextComp.scss'

const sizeMaps = {
    small: 'text--small',
    medium: 'text--medium',
    large: 'text--large',
    xlarge: 'text--Xlarge'
}

const TextComp = ({
    size = 'medium',
    className= "",
    children
}) => {

    const currentSizeClass = sizeMaps[size] || sizeMaps.medium

    return (
        <React.Fragment>
            <p className={`${currentSizeClass} ${className}`}>{children}</p>
        </React.Fragment>
    )
}

export default TextComp