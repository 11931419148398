import { fetchBlogs,API_URL } from "../../services/api";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';

const Card = () => {
  const [blogsData, setBlogsData] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    const fetchBlog = async () => {
      const data = await fetchBlogs();
      console.log(data,'data')
      setBlogsData(data);
    };

    fetchBlog();
  }, []);

  return (
    <React.Fragment>
    <div style={{
      display:'flex',
      flexDirection:'row'
    }}>
        {blogsData?.map((item) => (
      <div onClick={() => navigate(`/blog/${item.id}`)} style={{
        background: '#fff',
        border:'1px solid black',
        borderRadius: '10px',
        margin:'10px',
        overflow:'hidden',
        height:'267px',
        width:'300px',
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        cursor:'pointer'
      }} className="card">
          <div style={{
            margin:'10px'
          }}>
            <img style={{
               backgroundSize:'contain'
            }} height={"150"} width={'100%'} src={`https://dev-cmsadmin.spontaneousenterprise.com${item?.blog_image?.data?.url}`} />
            <p style={{ fontSize: '16px', fontWeight:'bold'}}>{item?.blog_title}</p>
            <p style={{ fontSize: '12px', display: "-webkit-box","-webkit-line-clamp": "3","-webkit-box-orient": "vertical","overflow": "hidden","max-height": "4.5em"}}>{item?.blog_description}</p>
          </div>
      </div>
        ))}
    </div>
    </React.Fragment>
  );
};

export default Card;
