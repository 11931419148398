import React from "react";
import Slider from "react-slick";
import "./ClientReviews.scss";

const reviews = [
  {
    name: "Mr. Amitkumar Jetwani",
    title: "Founder Bear Store, UK",
    text: `“I am absolutely thrilled with the exceptional service provided by Spontaneous Enterprise in setting up and configuring our e-commerce emporium. Their team demonstrated unparalleled expertise and efficiency, delivering the project with remarkable celerity. They ensured a seamless transition to an online boutique, optimizing its performance to guarantee expeditious loading times and an unparalleled user experience. The level of professionalism and unwavering commitment they exhibited throughout the process was truly astounding. Thanks to Spontaneous Enterprise, our digital storefront is now operational and streamlined, enabling us to efficiently engage customers and drive sales. I wholeheartedly endorse their services to anyone seeking cutting-edge software solutions.”`,
  },
  {
    name: "Mr. Markand Pathak",
    title: "CTO @ Leading reusable energy company in India",
    text: `"I had the pleasure of working with an exceptional IT company – “Spontaneous” for my business's digital transformation. Their supportive and intellectual team provided innovative solutions tailored to my needs. Their professionalism, promptness, and attention to detail were impressive. Thanks to their expertise, my business has experienced remarkable growth. Highly recommended!"`,
  },
  {
    name: "Mr. Pravin Jadhav",
    title: "HOD Automation Dept. Leading Tyre Manufacturer",
    text: `"I am incredibly impressed with Spontaneous Enterprise, the software development company's expertise in plant automation. They have provided us with an excellent software tool that has transformed our operations. The level of innovation and functionality in their solution is remarkable. Their team was highly professional, understanding our unique requirements and delivering a tailored solution that exceeded our expectations. The software has streamlined our processes, increased efficiency, and improved overall productivity. We are extremely satisfied with their service and highly recommend them to any company seeking top-notch software development for plant automation."`,
  },
  {
    name: "Mr. Rajesh Kumar",
    title: "Director, Leading Undergarment Company in India",
    text: `“I am extremely satisfied with Spontaneous Service of customized ERP solution for our garment industry. Led by Mr. Chirag, their team demonstrated exceptional knowledge in ERP implementation and configuration, aligning the system perfectly with our business requirements. The ERP solution has transformed our operations, improving inventory management, production planning, and financial processes for greater efficiency and productivity. Mr. Chirag provided invaluable guidance throughout the implementation, addressing our concerns promptly and customizing the system to meet our needs. I highly recommend this company for garment industries seeking a tailored ERP solution. Their expertise and commitment to customer satisfaction have been outstanding.”`,
  },
  {
    name: "Mr. Tonny",
    title: "CEO, Tech Company, U.K.",
    text: `“I am delighted to share my positive feedback on the resource-providing Information Technology Company – Spontaneous Enterprise. Their team of highly professional and technical experts has been instrumental in delivering projects with utmost efficiency and excellence. Their commitment to meeting deadlines and managing critical development tasks with high priorities has been exceptional. I have been impressed by their ability to handle complex technical challenges and find innovative solutions. The company's dedication to client satisfaction is evident in their proactive communication and willingness to go the extra mile to ensure project success. Their expertise and domain knowledge have greatly contributed to the smooth execution of our projects. I highly recommend this company for their reliable services and their ability to provide top-notch IT resources. Their team's technical prowess and professionalism make them a valuable partner for any organization.”`,
  },
  {
    name: "Mr. NitinKumar Raddy",
    title: "CTO, Leading Tech Company @ US.",
    text: `“Spontaneous Enterprise, the software company that delivered an extraordinary research service for our machine learning project in healthcare. Their team demonstrated exceptional expertise, conducting in-depth research and delivering innovative solutions that surpassed our expectations. They understood complex healthcare data and developed precise algorithms for image and data processing. Throughout the project, they exhibited professionalism, effective communication, and prompt responses. Their commitment to quality resulted in accurate and efficient solutions. Their research service played a crucial role in advancing our healthcare initiatives and improving patient outcomes. I highly recommend this software company for their exceptional research services in machine learning. Their expertise and dedication make them an invaluable partner for organizations seeking innovative solutions in healthcare.”`,
  },
  {
    name: "Mr. Yousef CEO",
    title: "Leading Shipping Service provider, Saudi Arabia",
    text: `“I am thrilled with the exceptional web and mobile application solutions provided by Spontaneous Enterprise for our logistics industry in Saudi Arabia. Their deep understanding of our industry needs, combined with their technical expertise, resulted in remarkable applications that perfectly catered to our requirements. The team's professionalism, proactive communication, and prompt support were truly impressive. They ensured timely project delivery and swiftly addressed any concerns that arose. The applications have revolutionized our logistics operations, boosting efficiency and elevating customer satisfaction. I highly recommend Spontaneous Enterprise for their reliable and impactful technology solutions tailored for the Saudi market.”`,
  },
];

const ClientReviews = () => {
  const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024, // Large screens (e.g., tablets)
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768, // Medium screens (e.g., landscape tablets, small laptops)
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 480, // Small screens (e.g., mobile devices)
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
  ],
};


  return (
    <section className="client-reviews">
      <h2 className="client-reviews__title">What Are Our Client Reviews?</h2>
      <p className="client-reviews__subtitle">
        Insights from our valued clients: their experiences speak volumes
      </p>
      <Slider {...settings} className="review-slider">
        {reviews.map((review, index) => (
          <div
            style={{
              width: "unset",
            }}
            key={index}
            className="review-card"
          >
            <h4 className="review-card__name">{review.name}</h4>
            <div className="review-card__title">{review.title}</div>
            <div className="review-card__text">
              <p>{review.text}</p>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default ClientReviews;
