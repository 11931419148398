import "../scss/CompanyValues.scss";
import React from "react";
import ImageComp from "./ImageComp";
import TextComp from "./TextComp";
import ClientShowCase from "./ClientShowCase";

const CompanyValues = () => {
  const valuesData = [
    {
      title: "Innovation:",
      description:
        "We embrace the latest technologies and emerging trends, such as AI, machine learning, blockchain, cyber security services, etc., to provide innovative solutions to our clients.",
      image: "/Resources/Innovation.png",
    },
    {
      title: "Customer-centric:",
      description:
        "We prioritize our client's needs and strive to deliver exceptional value through customized solutions tailored to their unique requirements.",
      image: "/Resources/Customer-Centric.png",
    },
    {
      title: "Collaboration:",
      description:
        "We work closely with our clients and stakeholders to build strong partnerships, foster open communication, and create a collaborative environment that encourages creativity and innovation.",
      image: "/Resources/Collobration.png",
    },
    {
      title: "Expertise:",
      description:
        "The team at Spontaneous Enterprises are subject matter experts who are passionate about technology and ensure that our clients always have access to the latest innovations.",
      image: "/Resources/Expertise.png",
    },
    {
      title: "Ethics and Transparency:",
      description:
        "We operate with the highest standards of ethics and transparency, ensuring that our client's trust is earned and maintained through honest, reliable, and transparent business practices.",
      image: "/Resources/Ethical-Trans.png",
    },
    {
      title: "Continuous Improvement:",
      description:
        "We continuously strive for improvement in our processes and solutions, ensuring we deliver the best possible outcomes for our clients.",
      image: "/Resources/Continues_improvement.png",
    },
  ];

  return (
    <div className="company--values">
      <ClientShowCase
        title="Our Esteemed Clients"
        desc="We are blessed and delighted to serve our clients who have trusted us with their brands. We have ensured that they rise and shine through the journey with our high-quality services."
      />
      <TextComp className="company--values--title" size="xlarge">
        We Go Hand-in-Hand With Our Super 6 Values
      </TextComp>
      {valuesData.map((value, index) => (
        <div
          className={`section ${index % 2 !== 0 ? "reverse" : ""}`}
          key={index}
        >
          <div className="indicator"></div>
          <div className="details">
            <div className="title">{value.title}</div>
            <div className="description">{value.description}</div>
          </div>
          <div className="image--container">
            <ImageComp height="150px" source={value.image} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CompanyValues;
