import React, { useState, useEffect } from "react";
import TextComp from "./TextComp";
import TabItem from "./TabItem";
import "./Technologies.scss";
import { useNavigate } from "react-router-dom";

const Technologies = ({ title, techItems, onTechClick = () => null }) => {
  const navigate = useNavigate()
  const [technologyData, setTechnologyData] = useState([])
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (techItems) {
      console.log(techItems, 'itemsd')
      setTechnologyData(Object.keys(techItems))
      setSelected(Object.keys(techItems)[0])
    }
  },[techItems])

  return (
    <React.Fragment>
      <div className="tab--main--container">
        <div className="tab--main--title">
          <TextComp size="large">{title}</TextComp>
        </div>
        <div className="tab--main--wrapper">
        <div className="all--tabs--container">
          {technologyData?.length > 0 && technologyData?.map((item) => (
            <React.Fragment>
              <TabItem
                isPanel={true}
                isSelected={item == selected}
                click={() => setSelected(item)}
                tabName={item}
              />
            </React.Fragment>
          ))}
        </div>
        <div className="tech-grid">
          {technologyData?.length > 0 && techItems && techItems[selected].map((item, index) => (
            <div onClick={() => {
              onTechClick()
              navigate(`/technology${item.path}`)
            }} key={index} className="tech-item">
              <img src={item.logo} alt={item.name} className="tech-logo" />
              <p>{item.name}</p>
            </div>
          ))}
        </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Technologies;
