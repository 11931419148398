import React, { useEffect, useState, useRef } from "react";
import TabItem from "./TabItem";
import ImageComp from "./ImageComp";
import { getCompanyInfo, getServices } from "../../services/api";
import "./Header.scss";
import ButtonComp from "./ButtonComp";
import PopupMenu from "./PopupMenu";
import Technologies from "./Technologies";
import Modal from "./Modal";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import ContactFormModal from "./ContactFormModal";
import { IMAGE_URL, getTechnologies } from "../../services/api";

const HeaderMenu = () => {
  const [technologies, setTechnologies] = useState();
  const [companyData, setCompanyData] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled] = useState(false);
  const [menuInfo, setMenuInfo] = useState([]);
  const popupRef = useRef(null);
  const tabItemRefs = useRef({});
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState(null); 
  const [services, setServices] = useState([]);
  const [hideHeader, setHideHeader] = useState(false); // New state for hiding header
  const lastScrollY = useRef(0); // Ref to keep track of the last scroll position
  const [isTop, setIsTop] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleAccordion = (tabName) => {
    setActiveAccordion((prevAccordion) =>
      prevAccordion === tabName ? null : tabName
    );
  };

  const fetchServices = async () => {
    try {
      const response = await getServices();
      setServices(response);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      const companyData = await getCompanyInfo();
    
      setCompanyData(companyData?.[0] || null);
    };

    fetchServices();
    fetchCompanyDetails();
    
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
     
      if (currentScrollY == 0) {
        setHideHeader(false)
        setIsTop(true)
      } if (currentScrollY > lastScrollY.current) {
        // User is scrolling down
        setIsTop(false)
        setHideHeader(true);
      } else if (currentScrollY > 0) {
        
        // User is scrolling up
        setIsTop(false)
        setHideHeader(false);
      }
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  

  const handleMouseLeave = (event) => {
    const isInsidePopup =
      popupRef.current && popupRef.current.contains(event.target);
    const isInsideTabs = Object.values(tabItemRefs.current).some(
      (ref) => ref && ref.contains(event.target)
    );

    if (!isInsidePopup && !isInsideTabs) {
      setCurrentTab(null);
      setIsMenuOpen(false);
    }
  };

  const getTechnologiesData = async () => {
    const data = await getTechnologies();
    if (data?.length > 0) {
      const transformedObject = data?.reduce((acc, item) => {
        // Extract the category (tech_type.technology_category) and format it to uppercase
        const category = String(
          item.tech_type.technology_category
        ).toUpperCase();

        // Map through the technologies to extract relevant details
        const technologies = item.technologies?.map((techItem) => ({
          name: techItem.tech_name,
          path: techItem.page_url,
          logo: IMAGE_URL + techItem?.tech_image?.url, // Assuming tech_image is part of techItem
        }));

        // Add the category and technologies to the accumulator object
        if (technologies) {
          acc[category] = technologies;
        }

        return acc;
      }, {});


      setTechnologies(transformedObject);
    }
  };

  useEffect(() => {
    getTechnologiesData()
    document.addEventListener("mouseover", handleMouseLeave);
    return () => {
      document.removeEventListener("mouseover", handleMouseLeave);
    };
  }, []);

  const handleTabMouseOver = (menu) => {
    setMenuInfo(menu);
    setIsMenuOpen(true);
  };

  return (
    <React.Fragment>
    <ContactFormModal setIsModalOpen={setIsModalOpen} modalOpen={isModalOpen} />
     <div
        className={`Header--wrapper ${ isTop ? "header--top" : scrolled ? "header--wrapper--scrolled" : ""} ${
          menuOpen ? "open" : ""
        } ${hideHeader ? "hide-header" : ""}`} // Add class for hiding header
      >
        <div className="Header--menu">
          <div  className="header--grid">
            <ImageComp
              onClick={() => navigate('/')}
              source={'/spontaneous-Logo.png'}
              altText="Company Logo"
              height={"auto"}
              width={230}
            />
            <div className="hamburger-menu" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faBars} />
            </div>
            <div className={`services--tabs ${menuOpen ? "accordion--mode" : ""}`}>
              {menuOpen && (
                <div className="accordion">
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      onClick={() => toggleAccordion("Services")}
                    >
                      <TabItem isActive={true} tabName="Services" />
                      <FontAwesomeIcon
                        icon={
                          activeAccordion === "Services"
                            ? faChevronUp
                            : faChevronDown
                        }
                      />
                    </div>
                    {activeAccordion === "Services" && (
                      <div className="accordion-content">
                        {services?.length > 0 &&
                          services.map((service, idx) => (
                            <div key={idx}>
                              <h4>{service.serviceName}</h4>
                              <ul>
                                {service.serviceInfo.map(
                                  (option, i) => (
                                    <li onClick={() => navigate(`/services${option.path}`)} key={i}>{option.name}</li>
                                  )
                                )}
                              </ul>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      onClick={() => toggleAccordion("Technologies")}
                    >
                      <TabItem tabName="Technologies" />
                      <FontAwesomeIcon
                        icon={
                          activeAccordion === "Technologies"
                            ? faChevronUp
                            : faChevronDown
                        }
                      />
                    </div>
                    {activeAccordion === "Technologies" && (
                      <div className="accordion-content">
                        <Technologies onTechClick={() => setIsMenuOpen(false)} techItems={technologies} title="Technologies We Work on" />
                      </div>
                    )}
                  </div>
                  <TabItem click={() => navigate("/blogs")} tabName="Blog" />
                  <TabItem
                    click={() => navigate("/aboutus")}
                    tabName="About Us"
                  />
                  <TabItem
                    click={() => navigate("/contactus")}
                    tabName="Contact Us"
                  />
                  <div className="get--in--touch">
                    <ButtonComp buttonTitle="Get In Touch" />
                  </div>
                </div>
              )}
              {!menuOpen && (
                <>
                  <TabItem click={() => navigate("/")} tabName="Home" />
                  <TabItem
                    ref={(el) => (tabItemRefs.current["Services"] = el)}
                    mouseOver={() => {
                      handleTabMouseOver(services);
                      setCurrentTab("Services");
                    }}
                    tabName="Services"
                  />
                  <TabItem
                    ref={(el) => (tabItemRefs.current["Technologies"] = el)}
                    mouseOver={() => {
                      setIsMenuOpen(true);
                      setCurrentTab("Technologies");
                    }}
                    tabName="Technologies"
                  />
                  <TabItem click={() => navigate("/blogs")} tabName="Blog" />
                  <TabItem
                    click={() => navigate("/aboutus")}
                    tabName="About Us"
                  />
                  <TabItem
                    click={() => navigate("/contactus")}
                    tabName="Contact Us"
                  />
                  <div className="get--in--touch">
                    <ButtonComp click={() => setIsModalOpen(true)}  buttonTitle="Get In Touch" />
                  </div>
                </>
              )}
            </div>
          </div>
          {currentTab === "Services" && (
            <div ref={popupRef}>
              <Modal isOpen={isMenuOpen}>
                <PopupMenu
                  click={(value) => {
                    setIsMenuOpen(false)
                    navigate(`/services${value.path}`);
                  }}
                  IsShowCustomSolution={currentTab === "Services"}
                  menu={
                    menuInfo?.length > 0
                      ? menuInfo.map((item) => ({
                          title: item?.serviceName,
                          options: item.serviceInfo,
                        }))
                      : []
                  }
                />
              </Modal>
            </div>
          )}
          {currentTab === "Technologies" && (
            <div ref={popupRef}>
              <Modal isOpen={isMenuOpen}>
                <Technologies onTechClick={() => setIsMenuOpen(false)} techItems={technologies} title="Technologies We Work on" />
              </Modal>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeaderMenu;
